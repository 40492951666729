import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../../entities/api';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  readonly apiEndpointDadosEducacionaisHomV1: string = 'https://dadoseducacionaishom.c3sl.ufpr.br/api/v1';

  readonly apiEndpointHomV1: string = 'https://simcaqhom.c3sl.ufpr.br/api/v1';

  private apiEndpoint: string = null;

  constructor(private http: HttpClient) { }

  post<T>(url: string, body: any, options?: any): Observable<T> {
    return this.http
      .post(url, body, options).pipe(
        map(response => this.extractData<T>(response)));
  }

  put<T>(url: string, body: any, options?: any): Observable<T> {
    return this.http
      .put(url, body, options).pipe(
        map(response => this.extractData<T>(response)));
  }

  delete<T>(url: string, options?: any): Observable<T> {
    return this.http
      .delete(url, options).pipe(
        map(response => this.extractData<T>(response)));
  }

  get<T>(url: string, options?: any): Observable<T> {
    return this.http
      .get(url, options).pipe(
        map(response => this.extractData<T>(response)));
  }

  getRequestOptionsWithSearchParams(searchParams: Map<string, string>): any {
    let params: HttpParams = new HttpParams();

    searchParams.forEach((value: string, key: string) => {
      params = params.set(key, value);
    });

    return { params: params };
  }

  getApiEndpoint(): Observable<string> {
    if (this.apiEndpoint != null) {
      return of(this.apiEndpoint);
    }

    return this.get<Api>('assets/data/api.json').pipe(
      map(api => {
        if (api) {
          this.apiEndpoint = api.endpoint;
        }

        return this.apiEndpoint;
      }));
  }

  private extractData<T>(response: any): T {
    return (response && response.result || response || {}) as T;
  }
}
